import { combineReducers, configureStore } from '@reduxjs/toolkit';

import appSlice from './app';
import voiceSlice from './voice';

const rootReducer = combineReducers({
  app: appSlice.reducer,
  voice: voiceSlice.reducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export const appActions = appSlice.actions;
export const voiceActions = voiceSlice.actions;
export { store };
