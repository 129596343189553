import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState } from '../store';
import { appManager } from '../managers';

let timer: Nullable<number> = null;

function GlobalCover() {
  const dispatch = useDispatch();
  const location = useLocation();
  const globalCover = useSelector((state: RootState) => state.app.globalCover);

  useEffect(() => {
    if (timer) clearTimeout(timer);
    if (globalCover) {
      // 10분 max. 원복.
      timer = window.setTimeout(() => appManager.setGlobalCover(false), 1000 * 60 * 10);
    }
  }, [globalCover, dispatch]);

  // 페이지변경시 커버 제거
  useEffect(() => {
    appManager.setGlobalCover(false);
  }, [location, dispatch]);

  if (!globalCover) return null;
  return <div className="cover loading-indicator"></div>;
}

export { GlobalCover };
