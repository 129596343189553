import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { GuardedRoute, GuardedRouteProps, GuardProvider } from 'react-router-guards';

import { GlobalLoading } from './components/GlobalLoading';
import { GlobalCover } from './components/GlobalCover';
import { GlobalModal } from './components/GlobalModal';

import { debugRoutes, DebugTools } from './components/DebugTools';
import { HistoryManager } from './components/HistoryManager';
import { reset, requireUserId, voiceGuard } from './routeGuards';
import { VoiceDetail, VoiceInfo, VoiceList, VoiceListSelectionOnly } from './pages/Voice';
import { VoiceTerms } from './pages/Terms';
import { NotFoundPage } from './pages/Errors';
import { VoiceCoupon } from './pages/Coupon';
import { TmapLogboxDispatcher } from './libs/logbox';

interface RouteConfig extends GuardedRouteProps {
  key: string;
  isSub?: boolean;
  backUrlAtFirstLanding?: string;
}
export const routes: RouteConfig[] = [
  { key: 'voiceInfo', path: '/voice/info', exact: true, component: VoiceInfo, meta: { auth: true } },
  { key: 'voiceTerms', path: '/voice/terms', exact: true, component: VoiceTerms, isSub: true },
  { key: 'voiceList', path: '/voice', exact: true, component: VoiceList, meta: { auth: true, starVoice: true } },
  { key: 'VoiceListSelectionOnly', path: '/voice/selection_only', exact: true, component: VoiceListSelectionOnly },
  {
    key: 'voiceDetail',
    path: '/voice/celeb/:id',
    exact: true,
    component: VoiceDetail,
    meta: { auth: true, starVoice: true },
    isSub: true,
  },
  { key: 'voiceCoupon' ,
    path: '/voice/coupon',
    exact: true,
    component: VoiceCoupon,
    meta: { auth: true, starVoice: true },
    isSub: true,
  },
  { key: 'notFound', path: '*', component: NotFoundPage },
];

function App() {
  return (
    <React.Suspense fallback="">
      <Router>
        <GlobalLoading />
        <GlobalCover />
        <GlobalModal />
        <DebugTools />
        <HistoryManager />
        <TmapLogboxDispatcher />
        <GuardProvider guards={[reset, requireUserId, voiceGuard]}>
          <Switch>
            <Route path="/" exact>
              <Redirect to="/voice" />
            </Route>
            {debugRoutes}
            {routes.map(config => (
              <GuardedRoute {...config} />
            ))}
          </Switch>
        </GuardProvider>
      </Router>
    </React.Suspense>
  );
}

export default App;
