import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { appManager } from '../../managers';

function NotFoundPage() {
  const dispatch = useDispatch();
  useEffect(() => {
    appManager.setSystemError({ message: '페이지를 찾을 수 없습니다.' });
  }, [dispatch]);
  return <></>;
}

export { NotFoundPage };
