import React, { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { find } from 'lodash-es';
import { utils } from '../../../libs/utils';

import { ProductItem } from '../../../types/voice';
import { RootState } from '../../../store';
import { voiceManager } from '../../../managers';
import { tmapLogbox } from "../../../libs/logbox";
import { Price } from './Price';

interface Props {
  itemData: ProductItem;
}

function CelebVoiceItem({ itemData }: Props) {
  const history = useHistory();
  const voiceState = useSelector((state: RootState) => state.voice);
  const purchaseList = useSelector((state: RootState) => state.voice.purchaseList);
  const purchaseInfo = useMemo(() => find(purchaseList, purchaseItem => purchaseItem.itemId === itemData.productId), [
    purchaseList,
    itemData.productId,
  ]);
  const isNew = useMemo(() => itemData.badge === 'new', [itemData]);
  const isPlaying = useMemo(() => itemData.productId === voiceState.playingGuideType, [voiceState.playingGuideType, itemData]);
  const buttonClassName = useMemo(() => {
    const result = [];
    if (isNew) result.push('new');
    if (isPlaying) result.push('tts');
    return result.join(' ');
  }, [isPlaying, isNew]);
  const id = useMemo(() => `voice_${itemData.productId}`, [itemData]);
  const logboxCustom = useMemo(() => JSON.stringify({product_id: itemData.productId}), [itemData.productId])

  const onClickPurchase = useCallback(
    itemData => {
      voiceManager.paymentAgreeCheck().then(() => {
        tmapLogbox.sendEvent("tap.product.detail", { product_id: itemData.productId })
        history.push(`/voice/celeb/${itemData.productId}`);
      });
    },
    [history],
  );

  return (
    <li className="celeb_voice_item">
      <div className="celeb_voice_thumb">
        {/* 신규 : new, 음원플레이 : tts */}
        <button type="button" className={`thumb ${buttonClassName}`} onClick={() => voiceManager.playTTS(itemData)}>
          {itemData.cover.thumbnailUrl ? <img src={itemData.cover.thumbnailUrl} width="74" className="img" height="74" alt="" /> : null}
          <span className="animate">
            <img src={require('../../../assets/images/play.gif').default} className="ani" alt="" />
          </span>
        </button>
      </div>
      {purchaseInfo ? (
        <>
          {/* 구매완료상품 */}
          <Link
              className="celeb_voice_info selected"
              to={`/voice/celeb/${itemData.productId}`}
              data-logbox-action="tap.product.detail"
              data-logbox-custom={logboxCustom}
          >
            <div className="title">
              <p className="name">{itemData.name}</p>
            </div>
            <p className="desc">{itemData.description}</p>
            <p className="period">이용기간: {utils.dateText(purchaseInfo.usagePeriod.usageEndDate)} 까지</p>
          </Link>
          <div className="radio_area">
            <input
              type="radio"
              name="voice_type"
              className="blind"
              id={id}
              onChange={() => voiceManager.setGuideType(itemData)}
              defaultChecked={itemData.productId === voiceState.selectedGuideType}
              data-logbox-action="tap.product.celeb"
              data-logbox-custom={logboxCustom}
            />
            <label className="label" htmlFor={id}>
              <span className="blind">{itemData.name}</span>
            </label>
          </div>
        </>
      ) : (
        <>
          {/* 판매중인상품 */}
          <button type="button" className="celeb_voice_info" onClick={() => onClickPurchase(itemData)}>
            <div className="title">
              <p className="name">{itemData.name}</p>
              <Badge itemData={itemData} />
            </div>
            <p className="desc">{itemData.description}</p>
            <Price itemData={itemData} />
            <span className={itemData.sale.price.sellingPrice === 0 ? 'btn_free' : 'btn_buy'}>
              {itemData.sale.price.sellingPrice === 0 ? '체험하기' : '구매하기'}
            </span>
          </button>
        </>
      )}
    </li>
  );
}

function Badge({ itemData }: Props) {
  const badge = itemData.badge;

  // D-day 계산
  const endDate = new Date(utils.dateString(itemData.sale.period.endDateTime, 'full')).getTime();
  const today = new Date().getTime();
  const gapDay = Math.floor((endDate - today) / 1000 / 60 / 60 / 24);
  const isDday = gapDay <= 10;

  // 우선순위 new, hot (tmap pick), dday, event
  if (badge === 'new') return null;
  // new는 이미지에 dot 표시
  else if (badge === 'hot') return <div className="icon pick">티맵 Pick</div>;
  else if (isDday) return <div className="icon dday">D-{gapDay}</div>;
  else if (badge === 'event') return <div className="icon event">Event</div>;

  return null;
}

export { CelebVoiceItem };
