import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash-es';

import { Footer, Header } from '../../components/Layout';

import { CelebVoiceItem, DefaultVoiceList } from './components';
import { selectPurchasedProductList } from '../../store/voice';
import { voiceManager } from '../../managers';

function VoiceListSelectionOnly() {
  const [inited, setInited] = useState(false);
  const purchasedProductList = useSelector(selectPurchasedProductList);

  useEffect(() => {
    voiceManager.resolveCacheDataForOnlySelection().then(() => {
      setInited(true)
    })
  }, [])

  return (
    <>
      <Header />

      {inited && (
        <div className="container">
          <div className="content">

            <section className="voice_type">
              <h1 className="voice_type_title">보이스 선택</h1>

              {/* 구매한상품 */}
              {!isEmpty(purchasedProductList) && (
                <ul className="celeb_voice_list">
                  {purchasedProductList!.map(itemData => (
                    <CelebVoiceItem itemData={itemData} key={itemData.productId} />
                  ))}
                </ul>
              )}

              {/* 기본보이스 */}
              <DefaultVoiceList />
            </section>

          </div>

          <Footer />
        </div>
      )}
    </>
  );
}

export { VoiceListSelectionOnly };
