import { get } from 'lodash-es';
import { $tmapAxios } from '../../libs/tmap-api';

function applySimulator() {
  let playTTSCallbackCache = '';
  let playTTSTimer;
  // pc tester
  window.TmapApp = Object.assign({}, window.TmapApp, {
    purchaseProduct(...args) {
      console.log('app - purchaseProduct', JSON.stringify(args));
      setTimeout(() => {
        $tmapAxios
          .post('/api-celeb/purchase/registerpayment', {
            purchaseItem: { itemId: args[0], purchaseToken: 'a0b6809b-fe40-40bc-8aef-a6585af5ffb1' },
          })
          .then(({ data }) => {
            window.TmapWebView.purchaseProductComplete('true', args[0], JSON.stringify(data.purchaseItem));
          });
      }, 1500);
    },
    setVoiceGuideType(...args) {
      console.log('app - setVoiceGuideType', JSON.stringify(args));
    },
    getVoiceGuideType(...args) {
      console.log('app - getVoiceGuideType', JSON.stringify(args));
      setTimeout(() => {
        const targetCallback = get(window.TmapWebView, args[0]);
        if (targetCallback) targetCallback(localStorage.getItem('voiceGuideType'));
      }, 0);
    },
    playTTS(...args) {
      console.log('app - playTTS', JSON.stringify(args));
      playTTSCallbackCache = args[3];
      playTTSTimer = setTimeout(() => {
        const targetCallback = get(window.TmapWebView, playTTSCallbackCache);
        if (targetCallback) {
          playTTSCallbackCache = '';
          targetCallback();
        }
      }, 5000);
    },
    stopTTS(...args) {
      console.log('app - stopTTS', JSON.stringify(args), playTTSCallbackCache, '-');
      clearTimeout(playTTSTimer);
      if (playTTSCallbackCache) {
        setTimeout(() => {
          const targetCallback = get(window.TmapWebView, playTTSCallbackCache);
          if (targetCallback) {
            playTTSCallbackCache = '';
            targetCallback();
          }
        }, 50);
      }
    },
    onBackKeyPressed(...args) {
      console.log('app - onBackKeyPressed', JSON.stringify(args));
      window.history.back();
    },
    openBrowser(url) {
      console.log('app - openBrowser', url);
      window.open(url);
    },
    getDisplayInfo(callback) {
      Promise.resolve().then(() => {
        const targetCallback = get(window.TmapWebView, callback);
        if (targetCallback) {
          targetCallback(
            JSON.stringify({
              displayHeight: window.outerWidth,
              displayWidth: window.outerHeight,
              screenHeight: window.screenWidth,
              screenWidth: window.screenHeight,
              webviewHeight: window.innerWidth,
              webviewWidth: window.innerHeight,
              statusBarHeight: 0,
              navigationBarHeight: 0,
              orientation: window.innerHeight > window.innerWidth ? 1 : 2,
            }),
          );
        }
      });
    },
    makeToast(str) {
      console.log('app - makeToast', str);
    },
    updateAccessKey(key) {
      console.log('app - updateAccessKey', key);
    },
  });
}

export { applySimulator };
