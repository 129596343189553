import qs from 'qs';
import { forEach, map } from 'lodash-es';
import compareVersions from "compare-versions";
import { eventBus } from './event-bus';
import { globalVars } from "./global-vars";

let uid = 0;
if (!window.TmapApp) window.TmapApp = {};
window.TmapWebView = window.TmapWebView || {};
window.TmapWebView.nativeCallback = {};
// 디바이스. 서비스마다 callback 호출시 TmapWebView 붙임여부다 다름. 자기참조 추가하여 호출 오류 방지
window.TmapWebView.TmapWebView = window.TmapWebView;

const userAgentLower = navigator.userAgent.toLowerCase();
const isIOS = userAgentLower.indexOf('ios') !== -1;

export enum TmapInterfaceEvent {
  CALL_INTERFACE = 'CALL_INTERFACE',
  RECEIVED_CALLBACK = 'RECEIVED_CALLBACK'
}

export enum AppsflyerInterfaceEvent {
  CLICK_BANNER = 'starvoice_ad_code',
  INTO_MAIN_VOICE = 'starvoice_select',
  INTO_DETAIL_VOICE = 'starvoice_detail',
  COUPON_INSERT = 'starvoice_coupon_insert',
  COUPON_INSERT_COMPLETE = 'starvoice_coupon_complete',
  PURCHASE_SUCCESS = 'starvoice_purchase_confirmed',
  PURCHASE_FAIL = 'starvoice_purchase_cancelled'
}

export const AppsflyerInterfaceParam = {
  DEFAULT_VOICE_FEMALE: { af_default_female: '여자 성우' },
  DEFAULT_VOICE_MALE: { af_default_male: '남자 성우' }
}

const IFRAME_ID_PREFIX = '__tmap_custom_url_call__'

const tmapInterface = {
  nativeCall(method: string, params?: any) {
    eventBus.emit(TmapInterfaceEvent.CALL_INTERFACE, method, params);
    if (isIOS) {
      let iframeContainer: Nullable<HTMLDivElement> = document.querySelector(`#interface_container`)
      if (!iframeContainer) {
        iframeContainer = document.createElement('div') as HTMLDivElement
        iframeContainer.id = 'interface_container'
        iframeContainer.style.display = 'none'
        document.body.appendChild(iframeContainer)
      }

      const iframeId = `${IFRAME_ID_PREFIX}${method}`
      let iframe: Nullable<HTMLIFrameElement> = document.querySelector(`#${iframeId}`)
      if (iframe) iframeContainer.removeChild(iframe)
      iframe = document.createElement('IFRAME') as HTMLIFrameElement
      iframe.id = iframeId
      iframeContainer.appendChild(iframe)

      const paramString = qs.stringify(params, { skipNulls: true });
      const customUrl = `tmapjs://${method}${paramString ? `?${paramString}` : ''}`;
      iframe.setAttribute('src', customUrl);
    } else {
      if (window.TmapApp[method]) return window.TmapApp[method](...map(params, val => val));
      else console.log(`NotFound interface - ${method}`);
    }
  },
  nativeCallback: window.TmapWebView.nativeCallback,
  generateEventName() {
    return `event_${uid++}`;
  },
  addNativeCallbackListener(callback: (...args: any[]) => void, uniqueKey: string = '') {
    if (uniqueKey) {
      forEach(this.nativeCallback, (callback, eName) => {
        if (eName.startsWith(uniqueKey)) delete this.nativeCallback[eName];
      });
    }
    const eventName = uniqueKey ? `${uniqueKey}_${this.generateEventName()}` : `${this.generateEventName()}`;
    this.nativeCallback[eventName] = (...result: any[]) => {
      callback.call(null, ...result);
      delete this.nativeCallback[eventName];
    };
    return `TmapWebView.nativeCallback.${eventName}`;
  },
  purchaseProduct(productId: string, price: number, cdn: string) {
    const params = { productId, price, cdn };
    console.log('web - purchaseProduct', JSON.stringify(params));
    this.nativeCall('purchaseProduct', params);
  },
  setVoiceGuideType(guideType: string, name: string, cdn: string, thumbnailUrl: string) {
    const params = { guideType, name, cdn, thumbnailUrl };
    console.log('web - setVoiceGuideType', JSON.stringify(params));
    this.nativeCall('setVoiceGuideType', params);
  },
  getVoiceGuideType(callback: (s: string) => void) {
    const callJS = this.addNativeCallbackListener(callback, 'guideType');
    const params = { callJS };
    this.nativeCall('getVoiceGuideType', params);
  },
  playTTS(guideType: string, cdn: string, ttsMessage: string, callback: Nullable<() => void>) {
    const callJS = typeof callback === 'function' ? this.addNativeCallbackListener(callback, 'playTTS') : 'TmapWebView.onStopTTS';
    const params = { guideType, cdn, ttsMessage, callJS };
    console.log('web - playTTS', JSON.stringify(params));
    this.nativeCall('playTTS', params);
  },
  stopTTS() {
    console.log('web - stopTTS');
    this.nativeCall('stopTTS');
  },
  onBackKeyPressed(params = { errorCode: '', errorMsg: '' }) {
    console.log('web - onBackKeyPressed');
    // 파라미터 있을 경우 9.0 미만에서는 history.back이고, 이후부터는 웹뷰 종료
    // onBackKeyPressed는 웹뷰 종료로 동작하는 것을 원칙으로 함.
    if(globalVars.isAndroid && compareVersions(globalVars.appVersion, '9.0.0') < 0) this.nativeCall('onBackKeyPressed')
    else this.nativeCall('onBackKeyPressed', params);
  },
  openBrowser(url: string) {
    this.nativeCall('openBrowser', { url });
  },
  getDisplayInfo(callback?: (dataStr: string) => any) {
    const callJS =
      typeof callback === 'function' ? this.addNativeCallbackListener(callback, 'displayInfo') : 'TmapWebView.onGetDisplayInfo';
    this.nativeCall('getDisplayInfo', { callJS });
  },
  makeToast(msg: string) {
    this.nativeCall('makeToast', { msg });
  },
  updateAccessKey(key: string) {
    this.nativeCall('updateAccessKey', { key });
  },
  getEUK(callback?: (value: string) => void) {
    const callbackJS = typeof callback === 'function' ? this.addNativeCallbackListener(callback, 'getEUK') : 'TmapWebView.getEUK';
    if (isIOS) {
      this.nativeCall('getEUK', { callbackJS });
    } else callback && callback(this.nativeCall('getEUK'));
  },
  recordEvent(name: string, json: object = {}) {
    // 8.13.0 버전 이후 적용
    if(compareVersions(globalVars.appVersion, '8.13.0') < 0) return;

    const params = { name, json: JSON.stringify(json) };
    console.log('appsflyer - recordEvent', JSON.stringify(params));
    this.nativeCall('recordEvent', params);
  },
  openServiceByName(serviceName: string, jsonData: any = null) {
    // 셀럽은 9.0버전 미만은 backKey 동작이 history.back임. 서비스 이동시 페이지 이동으로 처리
    if(compareVersions(globalVars.appVersion, '9.0.0') < 0) {
      switch(serviceName) {
        case 'customer-center':
          window.location.href = globalVars.userWebFaqUrl;
          break;
      }
    } else {
      this.nativeCall('openServiceByName', {serviceName, jsonData})
    }
  },
  getSessionId(callback?: (value: string) => void) {
    const callbackJS = typeof callback === 'function' ? this.addNativeCallbackListener(callback, 'getSessionId') : 'TmapWebView.getSessionId';
    if (isIOS) {
      this.nativeCall('getSessionId', { callbackJS });
    } else callback && callback(this.nativeCall('getSessionId'));
  },
  getDeviceId(callback?: (value: string) => void) {
    const callbackJS = typeof callback === 'function' ? this.addNativeCallbackListener(callback, 'getDeviceId') : 'TmapWebView.getDeviceId';
    if (isIOS) {
      this.nativeCall('getDeviceId', { callbackJS });
    } else callback && callback(this.nativeCall('getDeviceId'));
  },
  getCarrierName(callback?: (value: string) => void) {
    const callbackJS = typeof callback === 'function' ? this.addNativeCallbackListener(callback, 'getCarrierName') : 'TmapWebView.getCarrierName';
    if (isIOS) {
      this.nativeCall('getCarrierName', { callbackJS });
    } else callback && callback(this.nativeCall('getCarrierName'));
  },
};

// app >> web 방향 callback 수동정의
Object.assign(window.TmapWebView, {
  // 결제완료시
  purchaseProductComplete(isOK: string, productId: string, productDataString: string) {
    eventBus.emit(TmapInterfaceEvent.RECEIVED_CALLBACK, 'purchaseProductComplete', {isOK, productId, productDataString});
    eventBus.emit('nativeCallback.purchaseProductComplete', isOK === 'true', productId, productDataString);
  },
  onStopTTS() {
    eventBus.emit('nativeCallback.onStopTTS');
  },
  onGetDisplayInfo(dataStr: string) {
    eventBus.emit('nativeCallback.onGetDisplayInfo', JSON.parse(dataStr));
  },
});

export { tmapInterface };
