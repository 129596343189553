import React, { useState, useCallback } from 'react';
import { ModalEvent } from '..';
import { eventBus } from '../../../libs/event-bus';
import { VoiceServiceTerms } from '../../Terms/VoiceService';

interface Props {
  value?: boolean;
  callback?: Nullable<(a: any) => any>;
}

function VoiceServiceAgreement(props: Props) {
  const [checked, setChecked] = useState(false);

  const onClickButton = useCallback(
    result => {
      eventBus.emit(ModalEvent.CLOSE_MODAL, () => {
        if (props.callback) props.callback(result);
      });
    },
    [props],
  );

  return (
    <div className="popup_wrap">
      <div className="popup_container">
        <div className="popup_content">
          <section className="popup_terms">
            <div className="popup_terms_inner">
              <h1 className="title">
                셀럽을 이용하시려면 <span className="line">약관에 동의해주세요.</span>
              </h1>
              <div className="cont">
                <VoiceServiceTerms />
              </div>
            </div>
            <div className="checkbox_area">
              <input
                type="checkbox"
                id="agreement"
                className="blind"
                onChange={e => setChecked(e.target.checked)}
                defaultChecked={checked}
              />
              <label htmlFor="agreement" className="label">
                동의합니다.
              </label>
            </div>
          </section>
        </div>
        <footer className="popup_footer">
          <button type="submit" className="btn_confirm" disabled={!checked} onClick={() => onClickButton(checked)}>
            확인
          </button>
        </footer>
      </div>
      <div className="popup_dimmed" onClick={() => onClickButton(false)}></div>
    </div>
  );
}

export { VoiceServiceAgreement };
