import React from 'react';

function VoiceServiceTerms() {
  return (
    <>
      {/* prettier-ignore */}
      <>
        <em className="tit">여러분 환영합니다!</em>
        티맵모빌리티 주식회사(이하 'TMAP')가 제공하는 TMAP 셀럽 서비스를 이용해주셔서 감사합니다.
        TMAP은 회원 여러분이 TMAP 셀럽 서비스를 좀 더 편리하게 이용하실 수 있도록 그에 적용되는 서비스 약관을 마련했습니다.
        본 약관은 회원 여러분이 TMAP에서 사용하는 TMAP 셀럽 서비스를 이용하고자 하는 데 필요한 회사 간의 권리, 의무 기타 필요사항을 규정하고, TMAP 셀럽 서비스를 이용하는데 따른 구매 조건과 절차 등을 규정하는 것을 목적으로 하고 있습니다.
        조금만 시간을 내어 주의 깊게 읽어주시면 감사하겠습니다.<br/><br/>

        <em className="tit">들어가기에 앞서, 중요한 용어들을 정의해드립니다.</em>
        첫째, "TMAP 서비스" 란 회원 여러분이 PC, 이동전화, 휴대용 단말기, 차량단말기 등 각종 유무선 기기 또는 프로그램을 통하여 이용할 수 있도록 회사가 TMAP이라는 브랜드로 제공하는 내비게이션(교통정보 등 포함) 서비스 및 위치기반 정보 제공/추천 등 관련 제반 서비스를 말합니다.<br/>
        둘째, "TMAP 셀럽 서비스"란 TMAP 서비스의 길안내 음성을 연예인 등 셀럽의 목소리로 대체하는 유료 콘텐츠 서비스를 가리킵니다.<br/>
        셋째, "회원 여러분"이란 본 약관에 동의하고, TMAP 셀럽 서비스를 구매하여 이용하는 모든 고객을 의미합니다.<br/>
        정의되지 않은 약관상 용어의 의미는 TMAP이용약관과 서비스 별 안내에 의하고 그 이외의 사항은 관계법령 또는 일반적인 거래관행에 의합니다.<br/><br/>

        <em className="tit">약관의 명시 및 효력 변경을 안내드립니다.</em>
        이 약관은 TMAP 셀럽 서비스를 이용하고자 하는 모든 회원 여러분에 대해서 그 효력이 발생합니다.
        약관의 내용은 TMAP App. 및 TMAP 셀럽 서비스 구매페이지 내에 게시하거나 기타의 방법으로 회원 여러분께 공시하고, 회원 여러분이 이에 동의를 함으로써 효력이 발생합니다.
        TMAP은 필요하다고 인정되는 경우 '약관의 규제에 관한 법률' 등 관련 법령에 위배되지 않는 범위에서 약관을 변경할 수 있으며, TMAP이 약관을 변경할 경우에는 적용일자 및 변경사유를 명시하여 적용일자 7일 전부터 공지합니다.
        다만, 회원 여러분에게 불리한 약관의 변경은 적용일자 30일 전부터 공지해드리도록 하겠습니다.
        그리고 그 방법은 SMS, App. Push, e-mail 등을 통해 진행하겠습니다 (연락처 미기재, 변경 등으로 인하여 개별 통지가 어려운 경우에 한하여 위 공지를 함으로써 개별 통지한 것으로 간주합니다).<br/><br/>

        TMAP이 변경 약관을 공지 또는 통지하면서, 회원 여러분이 약관 변경 적용일까지 거부의사를 표시하지 않는다면 약관의 변경에 동의한 것으로 간주한다는 내용을 함께 공지 또는 통지하였음에도 불구하고 회원 여러분이 변경된 약관의 효력 발생일까지 약관 변경에 대한 거부의사를 명시적으로 표시하지 아니하면,
        변경 약관에 동의한 것으로 간주합니다.<br/><br/>

        회원 여러분은 변경된 약관에 동의하지 않을 경우 TMAP 셀럽 서비스 이용을 중단하고 구매 계약을 해지할 수 있습니다.
        본 약관에 동의하는 것은 TMAP이 운영하는 TMAP App.과 TMAP 사이트를 정기적으로 방문하여 약관의 변경사항을 확인하는 것에 동의함을 의미합니다.
        변경된 약관에 대한 정보를 알지 못하여 발생하는 피해에 대하여 TMAP은 책임이 없습니다.<br/><br/>

        또한 본 약관에 명시되지 않은 사항은 전자상거래 등에서의 소비자보호에 관한 법률 등 관련 법령의 규정과 TMAP이용약관, TMAP이 정한 서비스 운영정책, 일반 상관례 등에 의합니다.
        TMAP은 필요한 경우 TMAP 셀럽 서비스 이용과 관련된 세부적인 내용(이하 "이용정책"이라 함)을 정하여 이를 TMAP App. 등을 통하여 공지할 수 있습니다.
        본 약관에서 특별히 정하거나 TMAP이용약관과 본 약관의 내용이 다를 때는 본 약관의 내용이 우선합니다.<br/><br/>

        <em className="tit">TMAP 셀럽 서비스는 유료 서비스입니다.</em>
        TMAP 셀럽 서비스의 종류 및 이용요금, 이용기간 등은 서비스별로 상이하며 이와 관련된 정보는 각 서비스 표시 페이지를 통해 안내합니다.
        TMAP은 회원 여러분에게 더 나은 TMAP 셀럽 서비스의 제공을 위하여 일정 기간의 사전 공지를 통하여 TMAP 셀럽 서비스를 수시로 변경하거나 해당 서비스의 판매를 중단할 수 있으며,
        신규 TMAP 셀럽 서비스나 이벤트용 서비스를 추가할 수 있습니다.<br/><br/>

        TMAP 셀럽 서비스의 경우에는 해당 TMAP 셀럽 서비스에 명시된 요금을 지급하여야 이용할 수 있습니다.
        회원 여러분이 네트워크를 통해 애플리케이션을 다운로드하거나 TMAP 셀럽 서비스를 이용하는 경우에는 가입한 이동통신사에서 정한 별도의 요금이 발생할 수 있습니다.
        회원 여러분이 구매한 TMAP 셀럽 서비스는 해당 TMAP 셀럽 서비스를 다운로드 받거나 설치한 기기에서만 이용할 수 있습니다.<br/><br/>

        <em className="tit">이 부분은 주의해주셨으면 합니다.</em>
        회원 여러분이 TMAP을 통해 편안한 여행을 즐기며, 더욱 행복해지실 수 있다면 더할 나위 없는 기쁨이 될 것입니다.
        다만 회원 여러분은 TMAP의 TMAP 셀럽 서비스를 올바른 방법으로 이용해주셔야 한다는 점 또한 잊지 말아주셨으면 합니다.<br/><br/>

        우선 TMAP이 정한 구매 신청 양식에 따라 필요한 정보를 기입해주시기 바랍니다.
        타인의 정보를 도용하는 경우 TMAP 셀럽 서비스 이용이 제한되거나, 관계 법령에 따라 처벌 받을 수 있습니다.
        또한 구매 신청 시 허위정보를 제공하여서는 안되며, 허위 정보로 인해 발생하는 민형사상의 책임 및 문제에 대해서 TMAP은 책임지지 않습니다.<br/><br/>

        민법에 의한 미성년 회원 여러분께서 TMAP 셀럽 서비스를 구매 하고자 하는 경우,
        법정대리인(부모님)의 동의를 얻거나 계약 체결 후 추인을 얻지 않으면 미성년자 본인 또는 법정대리인이 그 계약을 취소할 수 있습니다.
        또한, 동일 IP 또는 유사한 계정에서 연속적인 결제 등 도용이나 기술적 오류, TMAP의 서비스에 지장을 주기 위한 것이라고 판단되는 경우 TMAP은 해당 구매계약을 취소할 수 있습니다.<br/><br/>

        그리고 회원 여러분이 상품을 구매할 때 사용할 수 있는 결제 수단은 사용 단말의 OS에 따라 iOS의 경우 애플의 IAP(In App Purchase), 안드로이드 OS의 경우 구글 빌링 등이 있습니다.
        단, TMAP 또는 결제회사의 사정에 따라 특정 결제 수단이 추가되거나 중단 또는 종료될 수 있습니다.<br/><br/>

        TMAP 셀럽 서비스의 구매계약 체결 완료 후에는, 구매계약 체결 사실 및 구매 내용을 e-mail이나 휴대폰(SMS)으로 통지드리겠습니다.
        회원 여러분의 결제 한도는 각 결제회사의 정책 또는 기준에 따르고, 결제회사가 정한 거래한도의 초과로 인하여 TMAP 셀럽 서비스의 구매가 불가능할 수 있습니다.
        회원 여러분은 각 결제회사의 시스템에 의하여 이용요금을 납부하며, 이용요금을 정상적으로 납부하지 않은 경우 TMAP 셀럽 서비스 이용이 제한될 수 있습니다.<br/><br/>

        <em className="tit">TMAP 셀럽은 서비스의 성격상 환불이 어렵습니다.</em>
        TMAP 셀럽 서비스의 구매계약 체결 후 TMAP 셀럽 서비스의 이용을 개시한 경우에는 디지털콘텐츠의 제공이 개시된 것이므로 전자상거래 등에서의 소비자보호에 관한 법률 제17조 제2항 제5호에 기하여 기결제 금액이 환불되지 않습니다.
        회원 여러분이 TMAP App.을 삭제하더라도 TMAP 셀럽 서비스 구매계약은 해제 또는 해지되지 않습니다.
        또한 회원 여러분이 TMAP 서비스 가입을 탈퇴하는 경우에는 개인정보 일체가 삭제되므로 추후 TMAP 서비스에 재가입하더라도 탈퇴 전 구매하신 TMAP 셀럽 서비스의 이용이 불가합니다.<br/><br/>

        <em className="tit">때로는 서비스가 중단될 수도 있습니다.</em>
        TMAP은 회원 여러분에게 TMAP 셀럽 서비스를 한시도 쉬지 않고 제공하기 위해 최선의 노력을 다합니다.
        다만, 서비스용 설비의 보수 등 공사로 인하여 부득이한 경우, 회원 여러분 혹은 제3자가 불법행위 등으로 TMAP의 영업활동을 방해하는 경우,
        영업의 폐지/ BP와의 계약 종료 등과 같은 TMAP의 제반 사정으로 서비스를 유지할 수 없는 경우,
        기타 천재지변, 국가비상사태, 정전 등 TMAP이 통제할 수 없는 불가항력적 사유가 있는 경우 TMAP 셀럽 서비스의 전부 또는 일부를 제한하거나 중단할 수 있습니다.<br/><br/>

        TMAP은 위에서 말씀드린 정지 또는 중단에 대하여 30일 전에 정지 또는 중단 사유,
        정지 또는 중단될 TMAP 셀럽 서비스의 내용 및 중단일자 등을 TMAP 셀럽 서비스 초기화면에 게시하거나 회원 여러분께 개별 통지드리겠습니다.
        단, 통제할 수 없는 사유로 인한 TMAP 셀럽 서비스의 중단(운영자의 고의, 과실이 없는 디스크 장애, 시스템 다운 등)으로 사전 통지가 불가능한 경우에는 그러하지 아니합니다.
        하지만 이러한 경우에도 상황을 파악하는 즉시 최대한 빠른 시일 내에 서비스를 복구하도록 노력하겠습니다.<br/><br/>

        <em className="tit">회원 여러분의 도움과 함께 최선의 책임을 다하겠습니다.</em>
        TMAP은 회사의 과실로 인하여 회원 여러분이 TMAP 셀럽 서비스를 이용하지 못하게 될 경우 본 약관 및 관련 법령에 따라 손해를 배상하겠습니다.
        이와 반대로 회원 여러분이 본 약관의 규정을 위반하여 TMAP에 손해를 끼쳤다면 회원 여러분은 TMAP에 발생하는 모든 손해를 배상하셔야 합니다.
        혹시라도 회원 여러분이 TMAP 셀럽 서비스를 이용하면서 불법행위를 하였거나 본 약관을 위반하여 TMAP이 회원 여러분 외의 제3자로부터 손해배상 청구 또는 소송을 비롯한 각종 이의를 제기 받는다면,
        회원 여러분은 자신의 책임과 비용으로 TMAP을 면책시켜야 하며, 이와 관련하여 발생한 모든 손해를 배상하셔야 합니다.<br/><br/>

        하지만 TMAP이 관계법령의 변경, 천재지변 또는 이에 준하는 불가항력으로 인하여 TMAP 셀럽 서비스를 제공할 수 없는 경우에는 TMAP 셀럽 서비스 제공에 관한 책임이 면제됩니다.
        또한 TMAP은 회원 여러분의 귀책사유로 인한 TMAP 셀럽 서비스 이용의 장애에 대하여는 책임을 지지 않습니다.
        TMAP은 회원 여러분이 TMAP 셀럽 서비스와 관련하여 인터넷에 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는 책임을 지지 않습니다.<br/><br/>

        본 약관에 따라 TMAP과 회원 여러분 사이에 발생한 전자거래의 분쟁에 대하여 소송이 제기될 경우 TMAP은 해결을 위하여 성실히 협의할 것입니다.
        그럼에도 불구하고 해결되지 않는다면 민사소송법에 따른 관할 법원에 소를 제기할 수 있습니다.<br/><br/>

        부칙<br/>
        이 약관은 2021년 3월 4일부터 적용됩니다.
      </>
    </>
  );
}

export { VoiceServiceTerms };
